import React from 'react';
import { Route, Switch } from 'react-router';
import FourOhFour from 'screens/fourOhFour';
import Layout from './components/Layout';
import Home from './home';

const Marketing = () => (
  <Layout>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route component={FourOhFour} />
    </Switch>
  </Layout>
);

export default Marketing;
