/* eslint-disable import/prefer-default-export */
import { httpRequest } from 'utils';
import ApplicationSettings from 'actions/AppSettings';

export const sendUserDetails = async (userDetails) => {
  const payload = {
    name: userDetails.name,
    emailAddress: userDetails.email,
    phone: userDetails.phone,
    company: userDetails.company,
    enquiryType: 1,
  };
  const appSettings = await ApplicationSettings.ReadConfig();
  const baseUrl = `${appSettings.REACT_APP_CONTACT_FORM_API_PATH}/ui`;
  const response = await httpRequest(`${baseUrl}/utilities/send-customer-enquiry`, 'post', null, payload);
  return response;
};

// https://develop.api.web.live-sign.com/ui/utilities/send-customer-enquiry
