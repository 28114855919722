import React from 'react';
import PropTypes from 'prop-types';
import {
  theme,
  CustomThemeProvider,
  themeOverrides,
  override,
} from '@galilee/lilee';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from 'components/GlobalStyle';
import ErrorBoundary from 'components/ErrorBoundary';

const livesignThemeOverrides = {
  name: 'liveSign',
  fonts: {
    body: "'mr-eaves-xl-modern', sans-serif",
    title: "'mr-eaves-xl-modern', sans-serif",
  },
  fontWeights: {
    text: 350, light: 300, medium: 400, bold: 700,
  },
  lineHeights: { standard: 1.5, display: 1.2, compact: 1 },
  fontSizes: ['12px', '14px', '16px', '21px', '28px', '36px', '48px', '58px'],
  space: [
    '0', //      0
    '2px', //    1
    '4px', //    2
    '8px', //    3
    '12px', //   4
    '16px', //   5
    '24px', //   6
    '32px', //   7
    '48px', //   8
    '64px', //   9
    '72px', //   10
    '160px', //  11
    '260px', //  12
  ],
  threshold: {
    sectionMaxWidth: '1200px',
    contentMaxWidth: '760px',
  },

  colors: {
    ...themeOverrides,

    primaryDark: '#299e04',
    primary: '#2DB004',
    primary80: '#57c036',
    primary60: '#81d068',
    primary40: '#abdf9b',
    primary20: '#d5efcd',
    primary10: '#eaf7e6',

    secondaryDark: '#2266a1',
    secondary: '#2671B3',
    secondary80: '#518dc2',
    secondary60: '#7daad1',
    secondary40: '#a8c6e1',
    secondary20: '#d4e3f0',
    secondary10: '#e9f1f7',

    tertiaryDark: '#6ee349',
    tertiary: '#7AFC51',
    tertiary80: '#95fd74',
    tertiary60: '#affd97',
    tertiary40: '#cafeb9',
    tertiary20: '#e4fedc',
    tertiary10: '#f2ffee',

    success: '#14CC9E',
    successLight: '#e8faf5',
    successDark: '#063d2f',

    warn: '#FF5119',
    warnLight: '#ffeee8',
    warnDark: '#4c1807',

    error: '#CC2945',
    errorLight: '#faeaec',
    errorDark: '#3d0c15',
  },
};

const livesignTheme = override(theme, livesignThemeOverrides);

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const RootProvider = ({ children }) => (
  <CustomThemeProvider theme={livesignTheme}>
    <BrowserRouter basename={baseUrl}>
      <GlobalStyle />
      <ErrorBoundary>{children}</ErrorBoundary>
    </BrowserRouter>
  </CustomThemeProvider>
);

RootProvider.defaultProps = {
  children: null,
};

RootProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default RootProvider;
