export default (function ApplicationSettings() {
  return {
    ReadConfig: async () => {
      const isProdMode = (process.env.REACT_APP_MODE === 'prod');
      try {
        const appSettingsDotJson = isProdMode
          ? '/appSettings.json'
          : '/appSettings.Development.json';
        const config = await fetch(appSettingsDotJson);
        const settings = await config.json();
        return settings;
      } catch (error) {
        const err = new Error(error);
        throw err;
      }
    },
  };
}());
