import { validators } from '@galilee/lilee';

export const base64EncodeUnicode = (str) => btoa(
  encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(`0x${p1}`)),
);

const getFriendlyErrorMessage = (status) => {
  if (status === 200) return 'OK';
  if (status === 201) return 'Created';
  if (status === 202) return 'Accepted';
  if (status === 203) return 'Non-Authoritative Information';
  if (status === 204) return 'No Content';
  if (status === 205) return 'Reset Content';
  if (status === 206) return 'Partial Content';
  if (status === 300) return 'Multiple Choices';
  if (status === 301) return 'Moved Permanently';
  if (status === 302) return 'Found';
  if (status === 303) return 'See Other';
  if (status === 304) return 'Not Modified';
  if (status === 305) return 'Use Proxy';
  if (status === 306) return 'Unused';
  if (status === 307) return 'Temporary Redirect';
  if (status === 400) return 'Bad Request';
  if (status === 401) return 'Unauthorized';
  if (status === 402) return 'Payment Required';
  if (status === 403) return 'Forbidden';
  if (status === 404) return 'Not Found';
  if (status === 405) return 'Method Not Allowed';
  if (status === 406) return 'Not Acceptable';
  if (status === 407) return 'Proxy Authentication Required';
  if (status === 408) return 'Request Timeout';
  if (status === 409) return 'Conflict';
  if (status === 410) return 'Gone';
  if (status === 411) return 'Length Required';
  if (status === 412) return 'Precondition Required';
  if (status === 413) return 'Request Entry Too Large';
  if (status === 414) return 'Request-URI Too Long';
  if (status === 415) return 'Unsupported Media Type';
  if (status === 416) return 'Requested Range Not Satisfiable';
  if (status === 417) return 'Expectation Failed';
  if (status === 418) return "I'm a teapot";
  if (status === 500) return 'Internal Server Error';
  if (status === 501) return 'Not Implemented';
  if (status === 502) return 'Bad Gateway';
  if (status === 503) return 'Service Unavailable';
  if (status === 504) return 'Gateway Timeout';
  if (status === 505) return 'HTTP Version Not Supported';
  return 'Indeterminable Error';
};

export const httpRequest = async (url, method, headers = null, payload = null) => {
  const config = {
    method,
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (
    (method.toLowerCase() === 'post' || method.toLowerCase() === 'patch')
    && payload
  ) {
    config.body = JSON.stringify(payload);
  }
  if (
    headers
    && typeof headers === 'object'
    && Object.keys(headers).length > 0
  ) {
    config.headers = { ...config.headers, ...headers };
  }

  return fetch(`${url}`, config).then(
    async (response) => {
      if (response.ok) {
        if (
          response.headers.get('Content-Type').indexOf('application/json') > -1
        ) {
          return response.json().then((json) => {
            if (json.succeeded && !json.result) return json;
            if (json.succeeded) return json.result;
            const error = json?.errors?.length > 0 ? json.errors[0] : 'Data access error';
            return Promise.reject(error);
          });
        }
        return response?.blob().then((blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          return blobUrl;
        });
      }
      return Promise.reject(getFriendlyErrorMessage(response.status));
    },
  );
};

export const validateField = (fieldName, fields) => {
  const { getValue, rules } = fields[fieldName];
  let errorMessage = null;
  if (!rules) return errorMessage;
  Object.keys(rules).find((validatorName) => {
    const rule = rules[validatorName];
    const { fieldRef, fieldRefs } = rule;
    if (fieldRef) {
      rule.value = fields[fieldRef].getValue();
    }
    if (fieldRefs) {
      rule.values = [];
      fieldRefs.forEach((ref) => rule.values.push(fields[ref].getValue()));
    }
    errorMessage = validators[validatorName](rule)(getValue());
    return typeof errorMessage === 'string';
  });
  return errorMessage;
};

export const validateForm = (validationRules) => {
  const validationErrors = {};
  Object.keys(validationRules).forEach((fieldName) => { validationErrors[fieldName] = validateField(fieldName, validationRules); });
  return validationErrors;
};
