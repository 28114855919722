import {
  Image, Box, H3, H4, mediaQueries,
} from '@galilee/lilee';
import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import Cordell from 'images/headshots/Cordell.jpg';
import Gavin from 'images/headshots/Gavin.jpg';
import Lara from 'images/headshots/Lara.jpg';
import Simon from 'images/headshots/Simon.jpg';

const teamMembers = [
  {
    name: 'Cordell Smith',
    role: 'Founder',
    picture: Cordell,
  },
  {
    name: 'Lara Paholski',
    role: 'CEO',
    picture: Lara,
  },
  {
    name: 'Gavin Kilbride',
    role: 'CPO',
    picture: Gavin,
  },
  {
    name: 'Simon Duke',
    role: 'Director',
    picture: Simon,
  },
];

const { greaterThan } = mediaQueries;

const TeamList = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(150px + ${(p) => p.theme.space[6]}*2));
  gap: ${(p) => p.theme.space[8]};
  justify-content: center;
  padding: 0;

  ${greaterThan[2]`
    justify-content: flex-start;
  `}
  ${greaterThan[3]`
    padding: 0 ${(p) => p.theme.space[7]};
  `}
  ${greaterThan[4]`
    justify-content: center;
    gap: ${(p) => p.theme.space[6]};
  `}
`;

export default function TeamMembers() {
  return (
    <TeamList>
      {teamMembers.map((p) => <TeamMember key={p.name} {...p} />)}
    </TeamList>
  );
}

const Card = styled(Box)`
    padding: ${(p) => p.theme.space[6]};
    display: flex;
    flex-flow: column nowrap;
    box-shadow: 0 2px 18px rgb(0, 0, 0, 0.3);
    border-radius: ${(p) => p.theme.radii[3]};
    box-sizing: border-box;
    background: ${(p) => p.theme.colors.white};
`;
const ImageCard = styled(Image)`
    height: 225px;
    width: 150px;
    min-width: 150px;
    object-fit: cover;
    box-shadow: 0 0 8px 8px white inset;
    margin-bottom: ${(p) => p.theme.space[5]};
`;

const TeamMember = ({ name, role, picture }) => (
  <Card>
    <ImageCard src={picture} alt={`${name} - ${role}`} />
    <H3>{name}</H3>
    <H4 color="darkOrange">{role}</H4>
  </Card>
);
TeamMember.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
};
