import React from 'react';
import { Text, Flex, Image } from '@galilee/lilee';
import Logo from 'images/TLSLogo.png';

const InDevelopment = () => (
  <Flex flexDirection="column" maxWidth="550px" px="5" mx="auto" my={[8, null, 9]} alignItems="center" width="100%">
    <Image maxWidth="400px" width="100%" src={Logo} />
    <Text lg mt="7" textAlign="center">
      We&apos;re in the process of making something great. Check back again soon.
    </Text>
  </Flex>
);

export default InDevelopment;
