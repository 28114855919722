import React from 'react';
import {
  Image, Flex,
} from '@galilee/lilee';
import Arrow from 'images/Arrow.png';
import HeaderImage from 'images/HeaderImage.png';
import LivesignImage from 'images/livesign-cloud-secure.png';
import LiveSignLogo from 'images/LiveSign.png';
import {
  MarketingSection,
  SectionText,
  SectionTitle,
  SectionSubtitle,
  AlternatingPair,
  ImgBackground,
  ExternalLink,
} from './components/Containers';
import ContactForm from './components/ContactForm';
import TeamMembers from './components/TeamMembers';

const Home = () => (
  <Flex flexDirection="column">
    {/* Header */}
    <MarketingSection pr={0} wide pb={0} pt={[10, 10, 10, 5, 0]}>
      <AlternatingPair wide>
        <AlternatingPair.Text>
          <Flex maxWidth="550px" mx="auto" flexDirection="column">
            <SectionSubtitle maxWidth="550px" fontSize={[5, null, null, '72px']} lineHeight={['32.4px', null, null, '64.8px']}>
              Innovative legal solutions
            </SectionSubtitle>
            <SectionText maxWidth="479px" fontSize={3}>
              Welcome to
              {' '}
              <b>thelawstore.com.au</b>
              , your innovative digital store providing simplified legal solutions through customer and business driven product design.
            </SectionText>
          </Flex>
        </AlternatingPair.Text>
        <AlternatingPair.Image>
          <Image src={HeaderImage} maxWidth={['160%', '120%', '120%', '100%']} />
        </AlternatingPair.Image>
      </AlternatingPair>
    </MarketingSection>
    {/* End Header */}

    <MarketingSection pr={0} wide py={10}>
      <AlternatingPair wide>
        <AlternatingPair.Image>
          <Image src={LivesignImage} maxWidth={['160%', '120%', '120%', '100%']} />
        </AlternatingPair.Image>
        <AlternatingPair.Text justifyContent="flex-start">
          <Flex mx="auto" flexDirection="column" maxWidth="552px">
            <Flex flexDirection="column">
              <Image src={LiveSignLogo} width="100%" maxWidth="215px" />
            </Flex>
            <Flex flexDirection="column">
              <SectionTitle color="orange" pt={[6, 6, 6, 6, 7]} uppercase>Our Products</SectionTitle>
              <SectionSubtitle fontSize={[4, null, null, 5]} lineHeight={['28px', '28px', '28px', '36px']}>
                livesign connects digital document signing with secure identity verification
              </SectionSubtitle>
              <SectionText>
                livesign is the only app that links digital signatures with government-issued photo ID precisely at the point of signing.
              </SectionText>
              <SectionText>
                Digitally verify your client&apos;s identity at the precise point of digital document signing. Know with certainty that the person signing your documents is who they say they are. Know your Signer.
              </SectionText>
              <Flex alignItems="center">
                <ExternalLink href="https://live-sign.com/" target="_blank" rel="noreferrer">Visit livesign</ExternalLink>
                <Image src={Arrow} width="100%" maxWidth={['12px']} height={['10px']} />
              </Flex>
            </Flex>
          </Flex>
        </AlternatingPair.Text>
      </AlternatingPair>
    </MarketingSection>

    {/* Team image section */}
    <ImgBackground pb={['40px', '130px', '140px', '310px', '385px']}>
      <MarketingSection pr={0} wide py={10}>
        <AlternatingPair wide>
          <AlternatingPair.Text justifyContent="flex-start">
            <Flex mx="auto" flexDirection="column" maxWidth="552px">
              <SectionTitle color="orange" uppercase>About Us</SectionTitle>
              <SectionSubtitle fontSize={[4, null, null, 5]} lineHeight={['28px', '28px', '28px', '36px']}>
                thelawstore.com.au is a customer focused, online business delivering legal solutions in a user friendly and clear manner.
              </SectionSubtitle>
            </Flex>
          </AlternatingPair.Text>
          <AlternatingPair.Text />
        </AlternatingPair>
      </MarketingSection>
    </ImgBackground>
    {/* Team image section End */}

    {/* Contact Form */}
    <MarketingSection bg="base" color="white" pr={0} wide py={[8, null, null, null, 10]}>
      <AlternatingPair wide alignItems={['center', 'center', 'center', 'flex-start']}>
        <AlternatingPair.Text>
          <Flex flexDirection="column" mx="auto" maxWidth="552px">
            <SectionTitle color="darkOrange" uppercase>Get started</SectionTitle>
            <SectionSubtitle lineHeight={['28px', null, '36px']} fontSize={[4, null, null, 5]}>To find out what thelawstore.com.au can do for your business, get in touch today.</SectionSubtitle>
            <SectionText lineHeight={['21.6px']} fontSize={['18px']}>
              Enter your details and one of our sales specialists will be in contact with you very soon.
            </SectionText>
          </Flex>
        </AlternatingPair.Text>
        <AlternatingPair.Text width="inherit">
          <Flex flexDirection="column" mx="auto" maxWidth="552px" width="100%">
            <ContactForm />
          </Flex>
        </AlternatingPair.Text>
      </AlternatingPair>
    </MarketingSection>
    {/* Contact Form */}

    <MarketingSection pr={0} wide py={[8, null, null, null, 10]}>
      <AlternatingPair wide>
        <AlternatingPair.Text justifyContent="flex-start">
          <Flex width="100%" mx="auto" flexDirection="column" maxWidth="552px">
            <SectionTitle color="orange" uppercase>Who we are</SectionTitle>
            <SectionSubtitle lineHeight={['28px', null, '36px']} fontSize={[4, null, null, 5]}>Meet the team.</SectionSubtitle>
          </Flex>
        </AlternatingPair.Text>
        <AlternatingPair.Text />
      </AlternatingPair>
      <AlternatingPair wide>
        <TeamMembers />
      </AlternatingPair>
    </MarketingSection>
  </Flex>
);

export default Home;
