import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {
  Flex, Image, Row, Column, H2, P,
} from '@galilee/lilee';
import Container from 'components/Container';

const ErrorContainer = styled(Flex)`
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: ${(p) => `${p.theme.space[11]} ${p.theme.space[4]}`};
  background-color: white;
`;

const TextColumn = styled(Column)`
  flex-direction: column;
  justify-content: center;

  ${H2} {
    margin-bottom: ${(p) => p.theme.space[6]};
  }

  ${P} {
    max-width: 510px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints[1]}) {
    align-items: center;
    margin-top: ${(p) => p.theme.space[9]};

    ${P} {
      text-align: center;
    }

    ${H2} {
    text-align: center;
  }
  }
  
`;

const ImageColumn = styled(Column)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorPage = ({ image, title, children }) => (
  <ErrorContainer>
    <Container center>
      <Row collapseIndex="1">
        <ImageColumn colspan="1">
          <Image src={image} width="215px" />
        </ImageColumn>
        <TextColumn colspan="2">
          <H2>{title}</H2>
          <Flex flexDirection="column" justifyContent="center">
            {children}
          </Flex>
        </TextColumn>
      </Row>
    </Container>
  </ErrorContainer>
);

ErrorPage.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorPage;
