import React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
} from '@galilee/lilee';

import TLSLogo from 'images/TLSLogo.png';
import NavBarLogo from 'images/NavBarLogo.png';
import Flex from '@galilee/lilee/dist/components/Flex';
import {
  HeadContainer, Head, BodyContainer, FooterContainer, Footer,
} from './Containers';

const Layout = ({ children }) => (
  <>
    <HeadContainer pt={6}>
      <Head wide>
        <Head.Logo>
          <Flex maxWidth={['480px', '492px', '492px', '552px']} width="100%" mx="auto" justifyContent="flex-start">
            <Image src={NavBarLogo} maxWidth={['237px', '315px', '315px']} />
          </Flex>
        </Head.Logo>
        <Head.Menu />
      </Head>
    </HeadContainer>
    <BodyContainer>
      {children}
    </BodyContainer>

    <FooterContainer wide py={9}>
      <Footer wide alignItems="center">
        <Footer.Logo>
          <Flex maxWidth={['480px', '492px', '492px', '552px']} width="100%" mx="auto" justifyContent="flex-start">
            <Image src={TLSLogo} height="100%" maxWidth="80px" />
            <Flex flexDirection="column" px={7}>
              <Footer.Text lineHeight="21px" fontSize={[2]} pb={5}>
                thelawstore.com.au is a firm of legal, digital and process specialists, assisting our clients to innovate and disrupt.
              </Footer.Text>
              <Footer.Text fontSize={[2]}>
                {`\u00A9${new Date().getFullYear()} thelawstore.com.au`}
              </Footer.Text>
            </Flex>
          </Flex>
        </Footer.Logo>
        <Footer.Menu />
      </Footer>
    </FooterContainer>
  </>
);

Layout.defaultProps = {
  children: null,
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Layout;
