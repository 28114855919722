import React from 'react';
import { Route, Switch } from 'react-router';
import Marketing from 'screens/marketing';
import InDevelopment from 'screens/marketing/inDevelopment';
import FourOhFour from 'screens/fourOhFour';

const App = () => {
  const Home = process.env.REACT_APP_IN_DEVELOPMENT === 'true' ? InDevelopment : Marketing;
  return (
    <>
      <Switch>
        <Route path="/" component={Home} />
        <Route component={FourOhFour} />
      </Switch>
    </>
  );
};

App.displayName = App.name;

export default App;
