import Button from '@galilee/lilee/dist/components/Button';
import Input from '@galilee/lilee/dist/components/Input';
import ToastNotifications from '@galilee/lilee/dist/components/ToastNotifications';
import React, { useState } from 'react';
import { sendUserDetails } from 'actions/Contact';
import { FormInputWrapper, Form } from './Containers';

const initUserDetails = {
  name: '',
  email: '',
  phone: '',
  company: '',
};
const ContactForm = () => {
  const [userDetails, setUserDetails] = useState(initUserDetails);
  const [notifications, setNotifications] = useState([]);
  const handleUserDetailsChange = (event) => {
    const { name, value } = event.target;
    setUserDetails({
      ...userDetails,
      [name]: value,

    });
  };
  const fakeClose = (id) => {
    setNotifications(notifications.filter((n) => n.id !== id));
  };

  const resetForm = () => {
    setUserDetails({
      name: '',
      email: '',
      phone: '',
      company: '',
    });
  };

  const handleUserDetailSubmit = (event) => {
    event.preventDefault();
    sendUserDetails(userDetails)
      .then(() => { setNotifications([...notifications, { id: Math.random().toString(), message: 'Contact Message Sent Successfully', type: 'success' }]); resetForm(); })
      .catch(() => { setNotifications([...notifications, { id: Math.random().toString(), message: 'Was not possible to send your message now.', type: 'error' }]); resetForm(); });
  };

  return (
    <>
      <ToastNotifications notifications={notifications} close={fakeClose} />
      <Form onSubmit={handleUserDetailSubmit}>
        <FormInputWrapper>
          <Input
            name="name"
            color="black"
            placeholder="Name"
            value={userDetails.name}
            onChange={handleUserDetailsChange}
            required
          />
        </FormInputWrapper>
        <FormInputWrapper>
          <Input
            name="email"
            color="black"
            type="email"
            placeholder="Email"
            value={userDetails.email}
            onChange={handleUserDetailsChange}
            required
          />
        </FormInputWrapper>
        <FormInputWrapper>
          <Input
            name="phone"
            color="black"
            type="tel"
            placeholder="Phone"
            value={userDetails.phone}
            onChange={handleUserDetailsChange}
            required
          />
        </FormInputWrapper>
        <FormInputWrapper>
          <Input
            name="company"
            color="black"
            placeholder="Company"
            value={userDetails.company}
            onChange={handleUserDetailsChange}
          />
        </FormInputWrapper>
        <Button type="submit" mt={6} color="secondary" maxWidth={10}>Send</Button>
      </Form>
    </>
  );
};

export default ContactForm;
