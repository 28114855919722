import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import RootProvider from 'state/RootProvider';
import App from 'App';
import ApplicationSettings from 'actions/AppSettings';

ApplicationSettings
  .ReadConfig()
  .then((settings) => {
    ReactDOM.render(
      <React.StrictMode>
        <RootProvider settings={settings}>
          <App />
        </RootProvider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });
