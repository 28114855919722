import styled from 'styled-components/macro';
import Team from 'images/Team.png';
import {
  Flex, theme, Row, Column, Text, Image,
} from '@galilee/lilee';

export const ExternalLink = styled.a`
  color: ${(p) => p.theme.colors.secondary} !important;
  font-size: 18px;
  padding-right: 4px;
  text-align: left;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: bold;
`;

const MarketingContainer = styled(Flex)`
 
`;

MarketingContainer.defaultProps = {
  px: [7, 8, null, null, null, 0],
  mx: 'auto',
  width: '100%',
};

// var breakpoints = ['576px', '768px', '992px', '1200px', '1440px'];
export const ImgBackground = styled(Flex)`
  background-image: url(${Team});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-color: #f2f2f2;
  @media (max-width: ${theme.breakpoints[3]}) {
    background-size: contain;
  }
  @media (min-width: ${theme.breakpoints[4]}) {
    padding-bottom:27.8%;
  }
  max-width: 100%;
  min-height: 100%;
`;

export const HeadContainer = styled(MarketingContainer)`
  border-bottom: 1px solid ${(p) => p.theme.colors.base10};
`;

HeadContainer.defaultProps = {
  alignItems: 'left',
  flexDirection: 'column',
};

export const Head = styled(Row)`
  max-width: ${(p) => p.theme.breakpoints[p.wide ? 4 : 3]};
  margin: auto;
`;

Head.defaultProps = {
  collapseIndex: 1,
};

Head.Logo = styled(Column)`
  margin-right: ${theme.space[4]};
  @media (max-width: ${theme.breakpoints[1]}) {
    order: 1;
  }
`;

Head.Logo.defaultProps = {
  justifyContent: 'center',
};

Head.Menu = styled(Column)`
  margin-right: ${theme.space[4]};
  @media (max-width: ${theme.breakpoints[1]}) {
    order: 1;
  }
`;

Head.Menu.defaultProps = {
  justifyContent: 'center',
};

export const FooterContainer = styled(MarketingContainer)``;

FooterContainer.defaultProps = {
  alignItems: 'left',
  flexDirection: 'column',
  wide: false,
};

export const Footer = styled(Row)`
  max-width: ${(p) => p.theme.breakpoints[p.wide ? 4 : 3]};
  margin: auto;
`;

Footer.defaultProps = {
  collapseIndex: 1,
};

Footer.Logo = styled(Column)`
  margin-right: ${theme.space[4]};
  @media (max-width: ${theme.breakpoints[1]}) {
    order: 1;
  }
`;

Footer.Logo.defaultProps = {
  justifyContent: 'center',
};

Footer.Text = styled(Text)`

`;

Footer.Menu = styled(Column)`
  margin-right: ${theme.space[4]};
  @media (max-width: ${theme.breakpoints[1]}) {
    order: 1;
  }
`;

Footer.Menu.defaultProps = {
  justifyContent: 'center',
};

export const BodyContainer = styled(Flex)`
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  flex-direction: column;
  @media (max-width: ${(p) => p.theme.breakpoints[1]}) {
    background-size: unset;
  }
`;

export const MarketingSection = styled(MarketingContainer)`
`;

MarketingSection.defaultProps = {
  alignItems: 'center',
  flexDirection: 'column',
  wide: false,
};

MarketingSection.Column = styled(Flex)`
  max-width: ${(p) => (`${Number(p.theme.breakpoints[3].replace('px', '')) / 2}px`)};
  align-self: ${(p) => p.alignSelf};
`;

MarketingSection.Column.defaultProps = {
  flexDirection: 'column',
  alignSelf: 'flex-start',
};

export const SectionTitle = styled(Text)`
`;

SectionTitle.defaultProps = {
  fontSize: 2,
  fontWeight: 'bold',
  mb: 3,
  lineHeight: 'compact',
  color: 'primary',
};

export const SectionText = styled(Text)``;

SectionText.defaultProps = {
  fontSize: '18px',
  fontWeight: 'light',
  mb: 5,
};
export const SectionSubtitle = styled(Text)`
`;
SectionSubtitle.defaultProps = {
  fontSize: [5],
  fontWeight: 'bold',
  lineHeight: 'display',
  mb: [6],
};

export const FormInputWrapper = styled(Column)`
  margin-bottom: ${(p) => p.theme.space[5]};
`;

export const Form = styled.form``;

const AlternatingPair = styled(Row)`
  max-width: ${(p) => p.theme.breakpoints[p.wide ? 4 : 3]};
`;

AlternatingPair.defaultProps = {
  collapseIndex: 1,
};

AlternatingPair.Image = styled(Column)`
  margin-right: ${theme.space[4]};
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.breakpoints[1]}) {
    order: 2;
  }
`;

AlternatingPair.Image.defaultProps = {
  mb: [8, null, 0],
};

AlternatingPair.Text = styled(Column)`
  margin-right: ${theme.space[4]};
  @media (max-width: ${theme.breakpoints[1]}) {
    order: 1;
  }
`;

AlternatingPair.Text.defaultProps = {
  justifyContent: 'center',
};

AlternatingPair.TextTitle = styled(SectionTitle)``;

AlternatingPair.TextTitle.defaultProps = {
  textAlign: ['center', null, 'left'],
};

export { AlternatingPair };

export const IconPanel = styled(Flex)``;

IconPanel.defaultProps = {
  alignItems: 'flex-start',
  flexDirection: ['column', 'row'],
  mb: 8,
};

IconPanel.Body = styled(Flex)``;

IconPanel.Body.defaultProps = {
  flexDirection: 'column',
};

IconPanel.Image = styled(Image)``;

IconPanel.Image.defaultProps = {
};

IconPanel.ImageContainer = styled(Flex)``;

IconPanel.ImageContainer.defaultProps = {
  alignItems: 'flex-start',
  justifyContent: 'center',
  minWidth: '110px',
  mr: 5,
  mb: 6,
};
